import React from "react"
import { Container } from "reactstrap"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { Accordion, Card } from "react-bootstrap"
import ContactForm from "../components/form/form"
import { StaticImage } from "gatsby-plugin-image"
import { Pagination, Navigation } from "swiper/dist/js/swiper.esm"
import "react-id-swiper/lib/styles/css/swiper.css"
import Swiper from "react-id-swiper"

const RentalsPage = ({ data, slidesperview }) => {
  const params = {
    modules: [Pagination, Navigation],
    slidesPerView: slidesperview,
    spaceBetween: 30,
    containerClass: "swiper-container pb-5",
    nested: true,
    autoplay: {
      delay: 5000,
      disableOnInteraction: false,
    },
    pagination: false,
    navigation: false,
    breakpoints: {
      768: {
        slidesPerView: 3,
        spaceBetween: 30,
      },
      640: {
        slidesPerView: 1,
        spaceBetween: 20,
      },
      320: {
        slidesPerView: 1,
        spaceBetween: 10,
      },
    },
  }
  return (
    <Layout>
      <SEO title="Rentals" description="Mile High Training, LLC" />
      <div className="rentals-page bg-light">
        <Container className="h-100">
          <div className="row justify-content-center h-100">
            <div className="col-md-12 h-100">
              <div className="h-100 d-flex justify-content-center align-items-center">
                <div className="rentals-inner text-center ">
                  <div>
                    {" "}
                    <h1 className="text-white text-capitalize mb-0">
                      Altitude equipment rental systems for less!
                    </h1>
                    <p className="lead text-white mb-5">
                      Rent for as long as you need the equipment to perfect your
                      altitude training.
                    </p>
                    {/* <div className="row">
                      {" "}
                      <div className="col-lg-4">
                        <div className="rental-hero-box1 d-flex justify-content-center align-items-center">
                          <a href="#" className="btn-solid mx-2">
                            Altitude Mask Rentals
                          </a>
                        </div>
                      </div>
                      <div className="col-lg-4">
                        {" "}
                        <div className="rental-hero-box2 d-flex justify-content-center align-items-center">
                          <a href="#" className="btn-solid mx-2">
                            Altitude Tent Rentals
                          </a>
                        </div>
                      </div>{" "}
                      <div className="col-lg-4">
                        {" "}
                        <div className="rental-hero-box3 d-flex justify-content-center align-items-center">
                          <a href="#" className="btn-solid mx-2">
                            Chamber Rentals
                          </a>
                        </div>
                      </div>
                    </div> */}
                    <Swiper {...params} slidesPerView={3}>
                      <div>
                        <div className="rental-hero-box1 d-flex justify-content-center align-items-center">
                          <a href="#" className="btn-solid mx-2">
                            Altitude Mask Rentals
                          </a>
                        </div>
                      </div>
                      <div>
                        <div className="rental-hero-box2 d-flex justify-content-center align-items-center">
                          <a href="#" className="btn-solid mx-2">
                            Altitude Tent Rentals
                          </a>
                        </div>
                      </div>
                      <div>
                        <div className="rental-hero-box3 d-flex justify-content-center align-items-center">
                          <a href="#" className="btn-solid mx-2">
                            Chamber Rentals
                          </a>
                        </div>
                      </div>
                    </Swiper>
                    {/* <div className="d-lg-flex d-inline">
                      {" "}
                      <a href="#" className="btn-solid mx-2">
                        Buy Altitude Equipment
                      </a>
                      <a href="#" className="btn-transparent  mx-2">
                        Consult with Us
                      </a>
                    </div> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Container>
      </div>
      <div className="section-padding">
        <Container>
          <h2>Reasons to rent an altitude system</h2>
          <Accordion>
            <Card>
              <Accordion.Toggle as={Card.Header} eventKey="0">
                <p
                  className="font-weight-bold pb-0"
                  style={{ fontSize: "22px", color: "#1b1b1b" }}
                >
                  PREVENT ALTITUDE SICKNESS
                </p>
              </Accordion.Toggle>

              <Accordion.Collapse eventKey="0">
                <Card.Body>
                  <p>
                    Many people traveling to up to altitude from sea-level are
                    not thoroughly prepared and this results in the inevitable
                    struggle to just feel normal. While some people are ok
                    traveling up to altitudes as high as Aspen, Colorado, others
                    struggle mightily when arriving in Denver. The reason is
                    that altitude effects everyone differently, regardless of
                    fitness level. In fact, the bodies’ response to high
                    altitude is primarily determined by genetics. Point being,
                    you may be a seasoned Ironman athlete in incredible shape,
                    but that doesn’t mean you too won’t succumb to the effects
                    of altitude. An altitude trainer or sleeping system is the
                    answer you’ve been looking for.
                  </p>
                  <p>
                    Our equipment provides a unique solution to dealing with
                    Altitude Sickness. Our low-oxygen air system (compressor,
                    hypoxicator, it goes by many names) enables the body to
                    slowly adjust to hypoxia (low oxygen state in the body). By
                    renting an altitude tent or mask system, you expose your
                    body to altitude conditions and can completely PRE-ACCLIMATE
                    before you arrive. If you’re taking a trip to high altitude,
                    don’t risk what thousands experience every year — make
                    Altitude Sickness a thing of the past!
                  </p>
                </Card.Body>
              </Accordion.Collapse>
            </Card>

            <Card>
              <Accordion.Toggle as={Card.Header} eventKey="1">
                <p
                  className="font-weight-bold pb-0"
                  style={{ fontSize: "22px", color: "#1b1b1b" }}
                >
                  PREPARE FOR HIGH ALTITUDE COMPETITION
                </p>
              </Accordion.Toggle>

              <Accordion.Collapse eventKey="1">
                <Card.Body>This is the tab body</Card.Body>
              </Accordion.Collapse>
            </Card>
            <Card>
              <Accordion.Toggle as={Card.Header} eventKey="2">
                <p
                  className="font-weight-bold pb-0"
                  style={{ fontSize: "22px", color: "#1b1b1b" }}
                >
                  ENHANCE SEA-LEVEL PERFORMANCE
                </p>
              </Accordion.Toggle>

              <Accordion.Collapse eventKey="2">
                <Card.Body>This is the tab body</Card.Body>
              </Accordion.Collapse>
            </Card>
            <Card>
              <Accordion.Toggle as={Card.Header} eventKey="3">
                <p
                  className="font-weight-bold pb-0"
                  style={{ fontSize: "22px", color: "#1b1b1b" }}
                >
                  WHAT EQUIPMENT IS INCLUDED?
                </p>
              </Accordion.Toggle>

              <Accordion.Collapse eventKey="3">
                <Card.Body>This is the tab body</Card.Body>
              </Accordion.Collapse>
            </Card>
            <Card>
              <Accordion.Toggle as={Card.Header} eventKey="4">
                <p
                  className="font-weight-bold pb-0"
                  style={{ fontSize: "22px", color: "#1b1b1b" }}
                >
                  WHY WE'RE DIFFERENT?
                </p>
              </Accordion.Toggle>

              <Accordion.Collapse eventKey="4">
                <Card.Body>This is the tab body</Card.Body>
              </Accordion.Collapse>
            </Card>
          </Accordion>
        </Container>
      </div>
      <div className="contact-rental">
        <Container className="h-100">
          {" "}
          <div
            className="text-center"
            style={{ maxWidth: "480px", margin: "0 auto" }}
          >
            {" "}
            <h1 className="text-capitalize mb-0">Altitude Rental Form</h1>
            <p className="mb-5">
              Please fill out the form to the right with your intended altitude
              equipment rental dates and we’ll get in touch!
            </p>
          </div>
          <div className="row justify-content-center h-100">
            <div className="col-md-5 h-100 d-flex align-items-center">
              <StaticImage
                className="p-0"
                layout="fixed"
                src="../images/rental-img.png"
                style={{ borderRadius: "5px" }}
              />
            </div>
            <div className="col-md-4 h-100">
              {" "}
              <div className="contact-inner">
                <ContactForm
                  form_name="Newsletter Form"
                  form_id="5bcd42f86b63453b251972bc"
                  form_classname="form-newsletter"
                >
                  <div className="form-row">
                    <div className="col-lg-12">
                      <div className="form-group">
                        <input
                          className="form-control"
                          type="text"
                          id="fullName"
                          placeholder="FullName"
                          required=""
                        />
                        {/* <label htmlFor="firstName">FullName</label> */}
                      </div>
                    </div>
                  </div>
                  <div className="form-group">
                    <input
                      className="form-control"
                      type="email"
                      id="homeEmail"
                      placeholder="Email address"
                      required=""
                    />
                  </div>
                  <div className="form-group">
                    <input
                      className="form-control"
                      id="phone"
                      placeholder="Phone (optional)"
                      type="tel"
                    />
                  </div>
                  <div className="form-group">
                    <textarea
                      className="form-control h-auto"
                      id="message"
                      placeholder="Message"
                      rows="10"
                      required=""
                    />
                  </div>
                  <div className="form-group">
                    <div className="webriq-recaptcha" />
                  </div>
                  <div className="form-group mt-3">
                    <button className="btn-transparent-white" type="submit">
                      Send Message
                    </button>
                  </div>
                </ContactForm>
              </div>
            </div>
          </div>
        </Container>
      </div>
      <section className="bottom-rental">
        <Container>
          <h1 className="text-center text-capitalize mb-0">More Options</h1>{" "}
          <Swiper {...params} slidesPerView={3}>
            <div>
              <div className="rental-hero-box4 d-flex justify-content-center align-items-center">
                <a href="#" className="btn-solid mx-2">
                  Altitude Mask Rentals
                </a>
              </div>
            </div>
            <div>
              <div className="rental-hero-box5 d-flex justify-content-center align-items-center">
                <a href="#" className="btn-solid mx-2">
                  Altitude Tent Rentals
                </a>
              </div>
            </div>
            <div>
              <div className="rental-hero-box6 d-flex justify-content-center align-items-center">
                <a href="#" className="btn-solid mx-2">
                  Chamber Rentals
                </a>
              </div>
            </div>
          </Swiper>
        </Container>
      </section>
    </Layout>
  )
}

export default RentalsPage
